import React, { createRef, useEffect } from "react";
import { Link } from "gatsby";
import { gsap } from "gsap";
import TransitionLink from "gatsby-plugin-transition-link";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import styled from "styled-components";
import background from "../../assets/images/colorful-plasticine-balls-VHHEW5P.png";
import Arrow from "../../assets/svg/arrowrightcodgray.inline.svg";
import { randomColor } from "../../utils/random-color";

const Container = styled.div`
	display: flex;
	min-height: 130px;
`;

const Header = styled.h2`
	font-family: "Poppins";
	font-weight: 900;
	font-style: normal;
	font-size: 2rem;
	line-height: 0.94;
	text-align: left;
	align-self: center;
	color: #1a1818;
	padding-left: 10px;
	text-transform: lowercase;
	white-space: pre-wrap;
	@media (min-width: 80em) {
		font-size: 3rem;
	}
`;

const ImageArea = styled.div`
	position: relative;
	padding-left: 100px;
	border-right: 3px solid #523178;
	background-image: url(${background});
	background-size: 140px;
	background-repeat: no-repeat;
`;

const ArrowWrapper = styled.div`
	position: absolute;
	bottom: 0;
	right: 0;
`;

const StyledLink = styled(AniLink)`
	display: flex;
	text-decoration: none;
	&:hover {
		text-decoration: none;
	}
`;

const Muovailu = ({
	data: {
		node: { title, slug, frontpageLinkTitle },
	},locale
}) => {
	const arrow = createRef();
	const bg = createRef();

	useEffect(() => {
		gsap.set(arrow.current, { x: -10, y: 0 });
		gsap.set(bg.current, { backgroundPosition: "0px -30px" });
	}, []);

	const mouseOver = () => {
		gsap.to(arrow.current, { duration: 0.15, x: 0 });
		gsap.to(bg.current, {
			backgroundPosition: "-10px -18px",
			duration: 0.15,
		});
	};
	const mouseLeave = () => {
		gsap.to(arrow.current, { duration: 0.15, x: -10 });
		gsap.to(bg.current, {
			backgroundPosition: "0px -30px",
			duration: 0.15,
		});
	};
	return (
		<Container>
			<StyledLink
				paintDrip
				hex={randomColor()}
				to={locale ? `/${locale}/${slug}` : `/${slug}`}
				onMouseEnter={mouseOver}
				onMouseLeave={mouseLeave}
			>
				<ImageArea ref={bg}>
					<ArrowWrapper ref={arrow}>
						<Arrow />
					</ArrowWrapper>
				</ImageArea>
				<Header>
					{frontpageLinkTitle.styledTitle
						? frontpageLinkTitle.styledTitle.replace(/-/g, "-\n")
						: title}
				</Header>
			</StyledLink>
		</Container>
	);
};

export default Muovailu;
