import React, { createRef, useEffect } from "react";
import { Link } from "gatsby";
import { gsap } from "gsap";
import TransitionLink from "gatsby-plugin-transition-link";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import styled from "styled-components";
import Arrow from "../../assets/svg/arrowyellowdown.inline.svg";
import History1 from "../../assets/images/history1.jpg";
import History2 from "../../assets/images/history3.jpg";
import History3 from "../../assets/images/history2.jpg";
import { randomColor } from "../../utils/random-color";
import { Flex, Image } from "@chakra-ui/react";

const Container = styled.div`
	max-width: 400px;
	//height: 213px;
	@media (min-width: 80em) {
		//height: 213px;
	}
`;

const Header = styled.h2`
	font-family: "Poppins";
	font-weight: 900;
	font-style: normal;
	font-size: 2rem;
	line-height: 0.94;
	text-align: left;
	color: #1a1818;
	background-color: #fff;
	z-index: 2;
	text-transform: lowercase;
	word-spacing:9999px;
	@media (min-width: 80em) {
		font-size: 2.85rem;
	}
`;

const ImageArea = styled.div`
	position: relative;
	width: 37%; //147px;
	//height: 213px;
	@media (min-width: 80em) {
		//height: 213px;
	}
`;

const ArrowWrapper = styled.div`
	position: absolute;
	top: 0;
`;

const StyledLink = styled(AniLink)`
	display: flex;
	flex-direction: row;
	height: 100%;
	text-decoration: none;
	&:hover {
		text-decoration: none;
	}
`;

const History = ({
	data: {
		node: { title, slug, frontpageLinkTitle },
	}, locale
}) => {
	const arrow = createRef();
	const image1 = createRef();
	const image2 = createRef();
	const image3 = createRef();

	useEffect(() => {
		gsap.set(arrow.current, { x: 10, y: 0 });
		gsap.set(image1.current, { x: 0, y: 0,  transformOrigin: '100% 0%' });
	}, []);	

	const linktext = frontpageLinkTitle.styledTitle
		? frontpageLinkTitle.styledTitle
		: title;

	const mouseOver = () => {
		gsap.to(arrow.current, {duration: 0.15, y: 10 });
		gsap.to(image1.current, {duration: 0.15, x: -6, y: 10, rotation: 4 });
		gsap.to(image2.current, {duration: 0.15, x: -70, y: -4, rotation: -6 });
		gsap.to(image3.current, {duration: 0.15, x: -90, y: 40, rotation: -10 });
	};
	const mouseLeave = () => {
		gsap.to(arrow.current, { duration: 0.15, y: 0 });
		gsap.to(image1.current, { duration: 0.15, x: 0, y: 0, rotation: 0 });
		gsap.to(image2.current, { duration: 0.15, x: 0, y: 0, rotation: 0 });
		gsap.to(image3.current, { duration: 0.15, x: 0, y: 0, rotation: 0 });
	};
	return (
		<Container>
			<StyledLink
				paintDrip
				hex={randomColor()}
				to={locale ? `/${locale}/${slug}` : `/${slug}`}
				onMouseEnter={mouseOver}
				onMouseLeave={mouseLeave}
			>
				<ImageArea>
					<Image pos="absolute" top="0" ref={image3} src={History3} htmlHeight={213} htmlWidth={147} />
					<Image pos="absolute" top="0" ref={image2} src={History2} htmlHeight={213} htmlWidth={147} />
					<Image pos="relative" ref={image1} src={History1} htmlHeight={213} htmlWidth={147} />
				</ImageArea>

				<Flex
					w="63%"
					//w="253px"
					//h="213px"
					ml="3px"
					pl="3px"
					borderLeftStyle="solid"
					borderLeftWidth="3px"
					borderLeftColor="#ffc600"
					direction="column"
					pos="relative"
					justifyContent="flex-end"
				>
					<ArrowWrapper ref={arrow}>
						<Arrow />
					</ArrowWrapper>
					<Header>{linktext}</Header>
				</Flex>
			</StyledLink>
		</Container>
	);
};

export default History;
