import React, { createRef, useEffect } from "react";
import { Link } from "gatsby";
import { gsap } from "gsap";
import TransitionLink from "gatsby-plugin-transition-link";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import styled from "styled-components";
//import ComingSoon from "../../assets/svg/tulossa.inline.svg";
import Arrow from "../../assets/svg/arrowdownpink.inline.svg";
import background from "../../assets/images/touko.png";
import { randomColor } from "../../utils/random-color";

const Container = styled.div`
	max-width: 360px;
`;

const Header = styled.h2`
	font-family: "Poppins";
	font-weight: 900;
	font-style: normal;
	font-size: 2rem;
	line-height: 0.94;
	text-align: left;
	padding-top: 1rem;
	padding-bottom: 1rem;
	color: #1a1818;
	background-color: #fff;
	z-index: 2;
	@media (min-width: 80em) {
		font-size: 2.8rem;
		width: 370px;
	}
`;

const ImageArea = styled.div`
	position: relative;
	padding-top: 120px;
	border-bottom: 3px solid #fcafc0;
    background-image: url(${background});
    background-size: contain;
    background-repeat: no-repeat;
`;

const ArrowWrapper = styled.div`
	position: absolute;
    bottom: 0;
`;

const StyledLink = styled(AniLink)`
	display: flex;
	flex-direction: column;
	text-decoration: none;
	&:hover {
		text-decoration: none;
	}
`;


const Touko = ({
	data: {
		node: { title, slug, frontpageLinkTitle },
	},locale
}) => {
	const arrow = createRef();
	const bg = createRef();

	useEffect(() => {
		gsap.set(arrow.current, { right: 2,  y: -10 });
		//gsap.to(arrow.current, { x: 155, y: -5});
		gsap.set(bg.current, { backgroundPosition: "left 54px"});
	}, []);

	const linktext = frontpageLinkTitle.styledTitle
		? frontpageLinkTitle.styledTitle
		: title;

	const mouseOver = () => {
        gsap.to(arrow.current, { y: -2, duration: 0.15 });
        
		//gsap.to(arrow.current, { duration: 0.15, autoAlpha: 1 }); // coming soon
        gsap.to(bg.current, { backgroundPosition: "left 9px",duration: 0.15 });
	};
	const mouseLeave = () => {
		gsap.to(arrow.current, { duration: 0.15, y: -10 });
		//gsap.to(arrow.current, { duration: 0.15, autoAlpha: 0 });
		gsap.to(bg.current, { backgroundPosition: "left 54px",duration: 0.15 });
	};
	return (
		<Container>
			<StyledLink
				paintDrip
				hex={randomColor()}
				to={`/${slug}`}
				onMouseEnter={mouseOver}
				onMouseLeave={mouseLeave}
			>
				<ImageArea ref={bg}>
				<ArrowWrapper ref={arrow}>	
				{ /* <ComingSoon />
					*/}
						<Arrow />
					</ArrowWrapper>
				</ImageArea>

				<Header>{linktext}</Header>
			</StyledLink>
		</Container>
	);
};

export default Touko;
