import React, { createRef, useEffect } from "react";
import { Link } from "gatsby";
import { gsap } from "gsap";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import styled from "styled-components";
import Arrow from "../../assets/svg/arrowbluedown.inline.svg";
import background from "../../assets/images/clouds.jpg";
import { randomColor } from "../../utils/random-color";

const Container = styled.div`
	max-width: 360px;
`;

const Header = styled.h2`
	font-family: "Poppins";
	font-weight: 900;
	font-style: normal;
	font-size: 2rem;
	line-height: 0.94;
	text-align: left;
	padding-top: 1rem;
	padding-bottom: 1rem;
	color: #1a1818;
	background-color: #fff;
	z-index: 2;
	white-space: pre-wrap;
	@media (min-width: 80em) {
		font-size: 2.85rem;
	}
`;

const ImageArea = styled.div`
	position: relative;
	padding-top: 100px;
	border-bottom: 3px solid #00358e;
    background-image: url(${background});
    background-size: 230px;
    background-repeat: no-repeat;
`;

const ArrowWrapper = styled.div`
	position: absolute;
	bottom: 0;
`;

const StyledLink = styled(AniLink)`
	display: flex;
	flex-direction: column;
	text-decoration: none;
	&:hover {
		text-decoration: none;
	}
`;

const Yhteistyo = ({
	data: {
		node: { title, slug, frontpageLinkTitle },
	},locale
}) => {
	const arrow = createRef();
	const bg = createRef();

	useEffect(() => {
		gsap.set(arrow.current, { x: 0, y: -10 });
		gsap.set(bg.current, { backgroundPosition: "right 23px"});
	}, []);

	const linktext = frontpageLinkTitle.styledTitle
		? frontpageLinkTitle.styledTitle
		: title;

	const mouseOver = () => {
		gsap.to(arrow.current, { y: -2, duration: 0.15 });
        gsap.to(bg.current, { backgroundPosition: "right 0px",duration: 0.15 });
	};
	const mouseLeave = () => {
		gsap.to(arrow.current, { duration: 0.15, y: -10 });
		gsap.to(bg.current, { backgroundPosition: "right 23px",duration: 0.15 });
	};
	return (
		<Container>
			<StyledLink
				paintDrip
				hex={randomColor()}
				to={locale ? `/${locale}/${slug}` : `/${slug}`}
				onMouseEnter={mouseOver}
				onMouseLeave={mouseLeave}
			>
				<ImageArea ref={bg}>
				<ArrowWrapper ref={arrow}>
						<Arrow />
					</ArrowWrapper>
				</ImageArea>

				<Header>{linktext.replace('-', '-\n').toLowerCase()}</Header>
			</StyledLink>
		</Container>
	);
};

export default Yhteistyo;
