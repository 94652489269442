import React, { createRef, useEffect } from "react";
import { Link } from "gatsby";
import { gsap } from "gsap";
import TransitionLink from "gatsby-plugin-transition-link";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import styled from "styled-components";
import Arrow from "../../assets/svg/arroworangeleft.inline.svg";
import MuscleSVG from "../../assets/svg/muscle.inline.svg";
import Mouth from "../../assets/svg/mouth.inline.svg";
import Finger from "../../assets/svg/finger.inline.svg";
import { randomColor } from "../../utils/random-color";

const Container = styled.div`
	display: flex;
	max-width: 360px;
`;

const Header = styled.h2`
	font-family: "Poppins";
	font-weight: 900;
	font-style: normal;
	font-size: 2rem;
	line-height: 0.94;
	text-align: left;
	padding-top: 1rem;
	padding-bottom: 1rem;
	color: #1a1818;
	background-color: #fff;
	z-index: 2;
	white-space: pre-wrap;
	text-transform: lowercase;
	@media (min-width: 80em) {
		font-size: 2.85rem;
	}
`;

const ImageArea = styled.div`
	position: relative;
	padding-right: 100px;
	border-left: 3px solid #ff8f1c;
`;

const ArrowWrapper = styled.div`
	position: absolute;
	bottom: 0;
`;

const Muscle = styled(MuscleSVG)`
	transform: scale(0.7);
`;
const MuscleWrapper = styled.div`
	position: absolute;
	left: -33px;
	top: -14px;
`;
const MouthWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 10px;
`;
const StyledLink = styled(AniLink)`
	display: flex;
	text-decoration: none;
	&:hover {
		text-decoration: none;
	}
`;

const Rohkaisu = ({
	data: {
		node: { title, slug, frontpageLinkTitle },
	},locale
}) => {
	const arrow = createRef();
	const muscle = createRef();

	useEffect(() => {
		gsap.set(arrow.current, { x: 10, y: 0 });
		//gsap.set(finger.current, { x: 33, y: 11 });
	}, []);

	const linktext = frontpageLinkTitle.styledTitle
		? frontpageLinkTitle.styledTitle
		: title;

	const mouseOver = () => {
		gsap.to(arrow.current, { x: 0, duration: 0.15 });
		//gsap.to(finger.current, { y: 0, duration: 0.15 });
	};
	const mouseLeave = () => {
		gsap.to(arrow.current, { duration: 0.15, x: 10 });
		//gsap.to(finger.current, { y: 11, duration: 0.15 });
	};
	return (
		<Container>
			<StyledLink
				paintDrip
				hex={randomColor()}
				to={locale ? `/${locale}/${slug}` : `/${slug}`}
				onMouseEnter={mouseOver}
				onMouseLeave={mouseLeave}
			>
				<Header>{linktext.replace(/-/g, '-\n')}</Header>
				<ImageArea>
					<MuscleWrapper ref={muscle}>
						<Muscle />
					</MuscleWrapper>
					<ArrowWrapper ref={arrow}>
						<Arrow />
					</ArrowWrapper>
				</ImageArea>
			</StyledLink>
		</Container>
	);
};

export default Rohkaisu;
