import React from "react";
import styled from "styled-components";
import Hiljainen from "./front-page/hiljainen";
import Muovailu from "./front-page/muovailu";
import Rohkaisu from "./front-page/rohkaisu";
import Yhteistyo from "./front-page/yhteistyo";
import History from "./front-page/historia";
import Touko from "./front-page/touko";

export default function FrontPageLinks({ pages, needle, locale }) {
	const pagedata = pages.find((item) => item.node.slug.includes(needle));
	switch (needle) {
		case "muovailu":
		case "dough":
		case "modelle":
			return <Muovailu data={pagedata} locale={locale} />;
			break;
		case "hiljainen":
		case "quiet":
		case "tysta":
			return <Hiljainen data={pagedata} locale={locale} />;
			break;
		case "rohkaisu":
		case "database":
		case "databas":
			return <Rohkaisu data={pagedata} locale={locale} />;
			break;
		case "yhteistyo":
		case "collaboration":
		case "samarbete":
			return <Yhteistyo data={pagedata} locale={locale} />;
			break;
		case "historia":
		case "history":
		case "historik":
			return <History data={pagedata} locale={locale} />;
			break;
		case "touko":
			return <Touko data={pagedata} locale={locale} />;
			break;
		default:
			return null;
	}
}
